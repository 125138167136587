import React from 'react';
import { createRoot } from 'react-dom/client';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import Map from './components/Map';

const root = createRoot(document.getElementById('root')!);

root.render(
  <ParentSize>{({ width, height }) => <Map width={width} height={height} />}</ParentSize>,
);
