import React, { useState } from 'react';
import * as topojson from 'topojson-client';
import { CustomProjection, Graticule } from '@visx/geo';
import {  geoMercator } from '@visx/vendor/d3-geo';
import { Zoom } from '@visx/zoom';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import topology from '../data/world-topo.json';
import recipes from '../data/recipes.json';
import { DialogActions } from '@mui/material';

export type GeoProps = {
  width: number;
  height: number;
  events?: boolean;
};

interface FeatureShape {
  type: 'Feature';
  id: string;
  geometry: { coordinates: [number, number][][]; type: 'Polygon' };
  properties: { name: string, active: boolean };
}

interface Recipe {
  title: string;
  link: string;
}

export const background = '#f1efe7';

// @ts-expect-error
const world = topojson.feature(topology, topology.objects.units) as {
  type: 'FeatureCollection';
  features: FeatureShape[];
};

function displayName(id: string | undefined, name: string | undefined) {
    let text = "";

    if (id !== undefined) {
      text = name + " (" + id + ")";
    }

    const countryNameElement = document.getElementById("country-name");

    if (countryNameElement !== null) {
      countryNameElement.innerHTML = text;
    }
}

function findRecipes(countryName: string) {
  const recipesByCountry = recipes.filter((recipe) => recipe.countryName === countryName)[0];

  if (recipesByCountry === undefined) {
    return [];
  }

  return recipesByCountry.recipes;
}

export default function GeoCustom({ width, height }: GeoProps) {
  const centerX = width / 2;
  const centerY = height / 2;
  const initialScale = (width / 630) * 100;

  const [countryName, setCountryName] = useState<string>("");
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const handleClickOpen = () => {
    setShowDialog(true);
  }

  const handleClose = () => {
    setShowDialog(false);
  }

  return width < 10 ? null : (
    <>
      <Dialog open={showDialog} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">{countryName}</DialogTitle>
        <DialogContent>
          {findRecipes(countryName).length === 0 ? (
            <DialogContentText id="alert-dialog-description">No posts found, come back later!</DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              {findRecipes(countryName).map((recipe) => (
                <div>
                  - <a href={recipe.link} target="_blank">{recipe.title}</a>
                </div>
              ))}
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
      <Zoom<SVGSVGElement>
        width={width}
        height={height}
        scaleXMin={100}
        scaleXMax={1000}
        scaleYMin={100}
        scaleYMax={1000}
        initialTransformMatrix={{
          scaleX: initialScale,
          scaleY: initialScale,
          translateX: centerX,
          translateY: centerY,
          skewX: 0,
          skewY: 0,
        }}
      >
        {(zoom: { isDragging: any; containerRef: React.LegacyRef<SVGSVGElement> | undefined; transformMatrix: { scaleX: number | undefined; translateX: number; translateY: number; }; }) => (
          <div className="container">
            <svg
              width={width}
              height={height}
              className={zoom.isDragging ? 'dragging' : undefined}
              ref={zoom.containerRef}
              style={{ touchAction: 'none' }}
            >
              <rect x={0} y={0} width={width} height={height} fill={background} rx={14} />
              <CustomProjection<FeatureShape>
                projection={ geoMercator }
                data={world.features}
                scale={zoom.transformMatrix.scaleX}
                translate={[zoom.transformMatrix.translateX, zoom.transformMatrix.translateY]}
              >
                {(customProjection) => (
                  <g>
                    <Graticule graticule={(g) => customProjection.path(g) || ''} stroke="rgba(33,33,33,0.05)" />
                    {customProjection.features.map(({ feature, path }, i) => (
                      <path
                        key={`map-feature-${i}`}
                        d={path || ''}
                        fill={feature.properties.active ? 'black' :  '#d1cfc7' }
                        stroke="black"
                        strokeWidth={0.5}
                        onClick={() => {handleClickOpen(); setCountryName(feature.properties.name)}}
                        onMouseEnter={() => {displayName(feature.id, feature.properties.name)}}
                        onMouseLeave={() => {displayName(undefined, undefined)}}
                      />
                    ))}
                  </g>
                )}
              </CustomProjection>
            </svg>
          </div>
        )}
      </Zoom>
    </>
  );
}
